<template>
  <div>
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      @close="isAlertErr = false"
    />
    <div class="banner">
      <img  :src="classDetail.image_url" class="img-banner" />
      <div class="body-banner">
        <div class="label-list">
            <div class="btn-white-oval font-poppins">Beginner Friendly</div>
            <div class="btn-white-oval font-poppins">Competent & Certified Instructors</div>
            <div class="btn-white-oval font-poppins">Live Class</div>
          </div>
        <h2 class="title-banner">
          {{ classDetail.name }}
        </h2>
        <span class="sub-title-banner"
          >Tingkatkan skill menjadi profesional dengan Job Guarantee Program dan dapatkan unlimited career support sampai kamu diterima kerja
        </span>

        <v-btn
          depressed
          color="#FFDE59"
          class="button-daftar"
          @click="goToCheckoutPage"
        >
          <span class="text-capitalize font-weight-bold blue-text font-poppins"
            >DAFTAR SEKARANG</span
          >
        </v-btn>
        <v-btn depressed color="white" class="button-daftar" @click="sendToWa">
          <span
            class="text-capitalize font-weight-bold blue-text font-poppins"
            >KONSULTASI GRATIS</span
          >
        </v-btn>
      </div>
    </div>
    <div class="detail-body">
      
      <div class="container">
        <span>Overview</span>
        <h5 class="title mb-4" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Mengapa Memulai Karir sebagai UI/UX Designer</h5>
        <div class="cada-parent">
              <div v-for="(data, idx) in overview" :key="idx" class="card-sayhay">
                <components :is="data.icon"></components>
                <h3>{{ data.title }}</h3>
                <p style="font-size: 14px" class="mt-4">{{ data.desc }}</p>
              </div>
            </div>
      </div>
      <div class="container mt-8">
        <span>Mengapa HiColleagues</span>
        <h5 class="title mb-4" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Wujudkan Karir Impian sebagai UI/UX Designer dengan Intensive Program Bootcamp HiColleagues</h5>
        <div class="card-hicol" v-for="(item, idx) in whyHiColl" :key="idx">
          <div>
            <img :src="item.img" width="70" alt="Ctn" />
          </div>
          <div>
            <h3>{{ item.title }}</h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
      <div class="container mt-8">
        <span>Target Peserta</span>
        <h5 class="title mb-4" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Kriteria Ideal Peserta Program Akselerasi Digital Bootcamp</h5>
        <img src="/img/class/main.png" alt="" style="width: 100%; height: 300px; border-radius: 16px; object-fit: cover;">
        <ul class="mt-7">
            <li v-for="(item, idx) in classDetail.target_audience" :key="idx">
              {{ item.level }}
            </li>
          </ul>
      </div>
      <div class="bg-container">
        <div style="margin-top: 45px; padding: 40px 0px">
          <span>Materi Silabus</span>
          <h5 class="title mb-4" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Materi yang Akan HiColers Pelajari</h5>
          <div v-for="(item, idx) in classDetail.summary_syllabus" :key="idx" class="faq-box" style="background-color: #F1F6FF" @click="item.show = !item.show">
            <div class="fax-box-info">
              <p style="width: 90%">{{ item.name }}</p>
            </div>
            <p v-if="item.show" class="faq-box-active" v-html="item.value"></p>
          </div>
        </div>
      </div>
      <div class="container">
        <span>Metode Belajar</span>
        <h5 class="title mb-4" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Ikuti Bootcamp HiColleagues dengan Metode Belajar yang Konstruktif</h5>
        <div v-for="(item, idx) in methodLearning" :key="idx" style="margin-top: 40px">
            <components :is="item.img" ></components>
          <div >
            <h3>{{ item.title }}</h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
      <div class="container">
        <span>Instruktur</span>
        <h5 class="title mb-4" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Belajar dari Mentor Kompeten & Tersertifikasi</h5>
        <div class="instructor-list">
              <div class="card-listis" v-for="(data, idx) in instructor" :key="idx">
                <img :src="$utils.loadImage(data.image_url)" alt="" style="width: 100%; border-radius: 16px 16px 0 0; height: 220px; object-fit: cover;" />
                <div style="padding: 20px 16px">
                  <p style="font-weight: bold; margin: 0;">{{ data.name }}</p>
                  <p class="mt-1" style="margin: 0; font-size: 12px;">{{ data.description.slice(0, 50) }}</p>
                </div>
              </div>
            </div>
      </div>
      <div class="container">
        <span>Jadwal & Biaya Pelatihan</span>
        <h5 class="title" style="line-height: 25px; margin-bottom: 20px; font-family: 'Poppins', sans-serif !important">Investasi Masa Depan yang Dapat HiColleagues Ikuti</h5>
        <div class="timeline-bootcamp mt-5" v-for="(item, idx) in classDetail.schedules" :key="idx">
                <p class="batch">{{item.name}} <br /> Batch {{ item.batch_number }}</p>
              <div class="info-schadule">
                <div class="info-schadule-ctn">
                  <span class="sm">Tanggal Dimulai</span>
                  <div class="nt">
                    <span class="bold">{{ formatDate(item.start_date) }}</span>
                    <span class="sm">Durasi, {{ $utils.getMonthDifference(item.start_date, item.end_date) }}</span>
                  </div>
                </div>
                <div class="info-schadule-ctn">
                  <span class="sm">Jadwal Kelas</span>
                  <div class="nt">
                    <span class="bold">{{ classDetail.days }}</span>
                    <span class="sm">{{ classDetail.hours }}</span>
                  </div>
                </div>
                <div class="info-schadule-ctn">
                  <span class="sm">Kuota Peserta</span>
                  <span class="bold">{{ item.total_audience }} Peserta</span>
                </div>
                <div class="info-schadule-ctn">
                  <span class="sm">Metode Pelatihan</span>
                  <div class="nt">
                    <span class="bold">{{
                    classDetail.method ? toMethode(classDetail.method) : "Oflline/Online"
                  }}</span>
                  <span class="sm cursor-pointer" v-if="classDetail.method == 'Offline'" style="text-decoration: underline;" @click="toLink(item.location_map)">
                    {{ item.location_address }}
                  </span>
                  
              </div>
              
              </div>
              <div class="info-schadule-ctn"  v-if="item.notes">
                  <span class="sm">Informasi Lain</span>
                  <div class="nt">
                    <span v-html="item.notes"></span>
                  </div>
                </div>
                </div>
              
              <div class="box-price">
                <p class="promo-early">{{classDetail.is_discount ? 'Special Promo' : 'Harga Normal'}}</p>
                <h2 v-if="!classDetail.is_discount">
                    {{ classDetail.price | toCurrency }}
                  </h2>
                <div v-else>
                  <span class="bold"><del>{{ classDetail.price | toCurrency }}</del></span>
                  <h2 class="my-0  mb-5" style="color: #162CA2;">{{  toDiscount(classDetail.price - classDetail.discount_price) }}</h2>
                  <span>Berlaku sampai <b>{{ formatDateInfo(classDetail.end_discount_date) }}</b></span>
                </div>
              
                  <p class="text-right mt-4">*S&K Berlaku</p>
              </div>
              <div class="box-price-info">
                <span style="font-size: 13px" class="bold">
                      Pendaftaran ditutup pada
                      {{ formatDateInfo(classDetail.registration_close_date) }}
                    </span>
                      <v-btn depressed color="#162CA2" @click="goToCheckoutPage" class="box-cta-daftar">
          <span class="text-capitalize font-weight-bold white--text"
            style="font-size: 15px; letter-spacing: 0 !important">DAFTAR SEKARANG</span>
        </v-btn>
        <v-btn outlined color="gray" class="box-cta-daftar" @click="sendToWa()">
          <span class="text-capitalize font-weight-bold"
            style="font-size: 15px; letter-spacing: 0 !important">KONSULTASI SEKARANG</span>
        </v-btn>
              </div>
            </div>
        <p v-if="!classDetail.schedules.length" class="text-center mt-10">Jadwal tidak ditemukan</p>
      </div>
      <div class="container">
        <span>Metode Pembayaran</span>
        <h5 class="title" style="line-height: 25px; margin-bottom: 20px; font-family: 'Poppins', sans-serif !important">Pilih Metode Pembayaran yang Cocok Sesuai Kebutuhanmu</h5>
        <div class="cada-parent">
              <div v-for="(data, idx) in paymenMethod" :key="idx" class="card-sayhay">
                <components :is="data.icon"></components>
                <h3>{{ data.title }}</h3>
                <p style="font-size: 14px" class="mt-4">{{ data.desc }}</p>
              </div>
            </div>
            </div>
            <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
          <v-expansion-panels flat class="faq-card">
            <v-expansion-panel style="border-radius: 20px; margin-bottom: 10px;" v-for="(item, index) in questions"
              :key="index">
              <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)" style="border-radius: 20px;">
                <h4 style="line-height: 22px">{{ item.title }}</h4>
                <template v-slot:actions>
                  <div class="icon" v-if="item.active" style="margin-right: 20px;">
                    <Arrow />
                  </div>
                  <div v-else style="margin-right: 20px;">
                    <Arrow :down="!item.active" />
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-radius:0 0 20px 20px;">
                <div v-html="item.description"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="btn-stoke-blue cursor-pointer mt-5" @click="$router.push('/faq')" >
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Grow from '@/assets/icons/Grow.vue';
import Methode from '@/assets/icons/Methode.vue';
import DateIcon from '@/assets/icons/Date.vue';
import Clock from '@/assets/icons/Clock.vue';
import CheckBlue from '@/assets/icons/Check.vue';
import CheckIc from '@/assets/icons/Check.vue';
import EmptyWallet from '@/assets/icons/bootcamp/EmptyWallet.vue';
import ThreadUp from '@/assets/icons/bootcamp/ThreadUp.vue';
import BriefCase from '@/assets/icons/bootcamp/BriefCase.vue';
import MoneyTick from '@/assets/icons/bootcamp/MoneyTick.vue';
import CardPost from '@/assets/icons/bootcamp/CardPost.vue';
import Popup from "@/components/others/Popup.vue";

// import Button from "../../others/Button.vue";
import ButtonYellow from '../../others/ButtonYellow.vue';
import M1 from '@/assets/icons/bootcamp/M1.vue';
import M2 from '@/assets/icons/bootcamp/M2.vue';
import M3 from '@/assets/icons/bootcamp/M3.vue';
import M4 from '@/assets/icons/bootcamp/M4.vue';
import M5 from '@/assets/icons/bootcamp/M5.vue';
import M6 from '@/assets/icons/bootcamp/M6.vue';
import M7 from '@/assets/icons/bootcamp/M7.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import ArrowLong from '@/assets/icons/ArrowLong.vue';


export default {
  name: 'DetailClassBootcampMobile',
  components: {
    // Button,
    CheckIc,
    ButtonYellow,
    CheckBlue,
    Grow,
    Methode,
    DateIcon,
    Clock,
    EmptyWallet,
    ThreadUp,
    BriefCase,
    MoneyTick,
    CardPost,
    Arrow,
    ArrowLong,
    M1,
    M2,
    M3,
    M4,
    M5,
    M6,
    M7,
    Popup
  },
  data() {
    return {
      whyHiColl: [
        {
          img: '/img/bootcamp/ic_intensive_program.png',
          title: 'Intensive Program',
          desc: 'Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri',
        },
        {
          img: '/img/bootcamp/ic_competent_trainer.png',
          title: 'Competent Trainer',
          desc: 'Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan',
        },
        {
          img: '/img/bootcamp/ic_sertifikat_completion.png',
          title: 'Learning Management System',
          desc: 'Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat',
        },
        {
          img: '/img/bootcamp/ic_sertifikat_kompetensi.png',
          title: 'E-Certificate',
          desc: 'Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir',
        },
        {
          img: '/img/bootcamp/ic_job_recommentation.png',
          title: 'Job Connector',
          desc: 'Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp',
        },
        {
          img: '/img/bootcamp/ic_installment.png',
          title: 'Installment Option Available',
          desc: 'Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta',
        },
      ],
      paymenMethod: [
      {
          icon: MoneyTick,
          title: "100% Upfront",
          desc: "Pembayaran dilakukan secara cash di muka dengan mengikuti biaya yang berlaku pada masa periode pendaftaran",
          btn: false,
        },
        {
          icon: MoneyTick,
          title: "Installment Payment",
          desc: "Pembayaran dilakukan dengan menerapkan metode cicilan bertahap 3x yang berlaku pada setiap periode pendaftaran",
          btn: true,
        },
        {
          icon: CardPost,
          title: "Company Sponsorship",
          desc: "Pembayaran dengan menerapkan anggaran pengembangan talenta perusahaan untuk mensponsori program akselerasi HiColleagues",
          btn: true,
        },
      ],
      overview: [
        {
          icon: EmptyWallet,
          title: "High-Paying Jobs",
          desc: "Berorientasi pada kebutuhan industri saat ini dengan tingginya permintaan Web Developer yang berperan dalam realisasi produk digital untuk membantuk performa bisnis perusahaan"
        },
        {
          icon: ThreadUp,
          title: "Promising Career Path",
          desc: "Pekerjaan yang berdampak pada profitabilitas dan performa bisnis perusahaan dengan peluang level keberlanjutan dalam kepemimpinan dan top management"
        },
        {
          icon: BriefCase,
          title: "Flexible Working",
          desc: "Pekerjaan yang dapat dilakukan dengan sistem kerja apapun dalam menunjang performa kerja, baik dengan sistem kerja Inhouse, Remote, maupun Freelance "
        },
      ],
      methodLearning: [
      {
          img: M1,
          title: "Join Community Group",
          desc: "Tersedia grup Telegram untuk memfasilitasi kolaborasi, konsultasi, dan pertukaran informasi antara peserta, trainer, dan admin grup.",
        },
        {
          img: M2,
          title: "Live Class via Zoom",
          desc: "Peserta akan mengikuti sesi pembelajaran secara daring menggunakan Zoom.",
        },
        {
          img: M3,
          title: "Access to LMS",
          desc: "Peserta mendapatkan akses LMS untuk mempermudah proses pembelajaran selama bootcamp.",
        },
        {
          img: M4,
          title: "Practical Learning",
          desc: "Peserta belajar melalui materi dan langsung menerapkannya dalam pengerjaan proyek yang relevan.",
        },
        {
          img: M5,
          title: "Sharing Session",
          desc: "Peserta dapat berdiskusi dan mendengarkan pengalaman langsung dari trainer ketika sesi pembelajaran berlangsung.",
        },
        {
          img: M6,
          title: "Live Consultation",
          desc: "Peserta berkesempatan mendapatkan bimbingan dan mentoring pribadi langsung dari trainer.",
        },
        {
          img: M7,
          title: "Final Project & Exam",
          desc: "Pada akhir pembelajaran, peserta wajib untuk mengerjakan proyek akhir dan ujian akhir untuk mengukur pemahaman peserta.",
        },
      ],
      menuPublicClass: [
        { name: 'Info Kelas', id: 1, position: 0, layout: 'info-kelas' },
        {
          name: 'Timeline Belajar',
          id: 2,
          position: 0,
          layout: 'timeline-belajar',
        },
        { name: 'Materi Silabus', id: 3, position: 0, layout: 'materi-silabus' },
        { name: 'Benefit', id: 4, position: 0, layout: 'benefit' },
        {
          name: 'Tujuan Pelatihan',
          id: 5,
          position: 0,
          layout: 'tujuan-pelatihan',
        },
        { name: 'Target Peserta', id: 6, position: 0, layout: 'tagret-peserta' },
        {
          name: 'Kenapa HiColleagues',
          id: 7,
          position: 0,
          layout: 'kenapa-hicoleagues',
        },
        { name: 'Metode Belajar', id: 8, position: 0, layout: 'methode-belajar' },
        {
          name: 'Biaya Pelatihan',
          id: 9,
          position: 0,
          layout: 'biaya-pelatihan',
        },
      ],
      methodePembayaran: [
        {
          title: 'Pembayaran Pertama',
          type: '(Saat Pendaftaran)',
          list: [
            '<span>Ketika calon peserta mendaftar untuk bootcamp, calon peserta akan diminta untuk</span> membayar pembayaran pertama.',
            '<span>Besaran</span> pembayaran pertama adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>',
            '<span>Calon peserta akan dianggap</span> resmi terdaftar setelah pembayaran pertama diterima',
          ],
        },
        {
          title: 'Pembayaran Kedua',
          type: '(Tengah Training / Senin, 13 November 2023)',
          list: [
            'Pembayaran kedua akan jatuh tempo pada tengah periode pelatihan, <span>yaitu pada tanggal yang telah ditentukan dalam perjanjian pendaftaran.</span>',
            '<span>Besaran</span> pembayaran kedua juga adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>',
            '<span>Peserta akan</span> diingatkan sebelum jatuh tempo <span>pembayaran kedua</span>',
          ],
        },
        {
          title: 'Pembayaran Ketiga',
          type: '(Sebelum Penerimaan Sertifikat)',
          list: [
            '<span>Pembayaran ketiga akan dilakukan setelah calon peserta</span> menyelesaikan seluruh pelatihan dan memenuhi semua persyaratan <span>yang diperlukan.</span>',
            'Pembayaran ketiga adalah sisa dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>',
            '<span>Peserta akan diingatkan</span> sebelum jatuh tempo <span>pembayaran ketiga.</span>',
            '<span>Setelah pembayaran ketiga diterima, peserta akan diberikan</span> sertifikat sebagai bukti kelulusan.',
          ],
        },
      ],
      priceAddInfo: ['Tipe kelas Public', 'Kapasitas max 10 peserta', 'Jadwal kelas telah ditentukan', 'Diajarkan langsung oleh mentor', 'Gratis Survival Kit', 'Sertifikat Kelulusan'],
      classDetail: {},
      isAlertErr: false,
      alertErrMessage: "",
      expired: [],
      instructor: [],
      questions: [],
      layout: 'info-kelas',
    };
  },
  methods: {
    sendToWa() {
      window.open('https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya');
    },
    goToCheckoutPage() {
      if (new Date(this.classDetail.registration_close_date) < new Date()) {
        this.alertErrMessage = "Pendaftaran sudah di tutup";
        this.isAlertErr = true;
        return;
      }
      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.is_discount ? this.classDetail.price - this.classDetail.discount_price : this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: 'BOOTCAMP_CLASS',
        methode: this.classDetail.method,
        days: this.classDetail.days,
        schedule: this.classDetail.schedules,
        category_id: this.classDetail.category_id,
      });
      localStorage.clear();
      localStorage.setItem('checkout', req);
      window.location.href = '/checkout';
    },
    toDiscount(total_price) {
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    goToCustomerService() {
      window.location.href = 'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya';
    },
    toMethode(value) {
      if (!value) return
      if (value.toLowerCase() === "online") {
        return "Daring (via Zoom)"
      } else if (value.toLowerCase() === "offline") {
        return "Luring"
      } else {
        return value
      }
    },
    toLink(link) {
      window.open(link)
    },
    formatDateInfo(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=BOOTCAMP&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(this.classDetail.end_discount_date);
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get('/v1/class-detail/' + this.$route.params.slug_name)
        .then((response) => {
          const data = response.data.data.class_detail;
          this.classDetail = {
            ...data,
            summary_syllabus: data.summary_syllabus
              ? data.summary_syllabus.map((item) => ({
                  ...item,
                  show: true,
                }))
              : [],
          };
          this.instructor = [
            response.data.data.instructor
          ]
          this.countDownTimer();

        })
        .catch((err) => console.error(err));
      // .finally(() => this.spin_load.val = false)
    },
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },

  },
  mounted() {
    this.getDetailClass();
    window.scrollTo(0, 0);
    this.getFaq()
  },
};
</script>

<style src="./css/detailClassBootcamp.css" scoped></style>