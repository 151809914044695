<template>
  <div class="container container-size">
    <div class="navigation">
      <span class="nav-active" @click="$router.push('/event')">Home</span>
      <Arrow :width="20" direct="left" />
      <span>Detail Event</span>
    </div>
    <div class="event-content">
      <div class="event-content-l">
        <img
          :src="event.img_urls.desktop.product_detail"
          alt="Banner"
          class="event-img"
        />
        <div class="event-info">
          <h1 class="line-height-normal">{{ event.name }}</h1>
          <div class="event-ctn-label">
            <span>{{ event.category.name }}</span>
          </div>
          <p class="description" v-html="event.description"></p>
          <h2 class="mt-10 mekenisme-text">Mekanisme Event</h2>
          <div class="timeline-bootcamp mt-5">
            <div class="info-schadule">
              <div class="info-schadule-ctn">
                <GrowIc :width="25" fill="#7B7E8C" />
                <span>Semua Level</span>
              </div>
              <div class="info-schadule-ctn">
                <PeopleIc :width="25" fill="#7B7E8C" />
                <span>{{
                  event.participant_quota
                    ? `${totalRegistrant(total_registrants) >= 4 ? total_registrants + " /" : ""} ${event.participant_quota}  Peserta` 
                    : "Tidak terbatas"
                }}</span>
              </div>
              <div class="info-schadule-ctn">
                <DateIc :width="25" fill="#7B7E8C" />
                <span>{{ event.total_session }} Sesi</span>
              </div>
              <div class="info-schadule-ctn">
                <MethodeIc :width="25" fill="#7B7E8C" />
                <span>{{ event.method | toMethode }}</span>
              </div>
            </div>
            <img
              src="/img/icons/line-strip.png"
              alt="Link"
              style="width: 100%; margin-top: -20px"
            />
            <div class="info-schadule" style="display: block">
              <div class="info-schadule-ctn mb-2">
                <ClockIc :width="25" fill="#7B7E8C" />
                <span style="margin-top: -5px">Jam Pelaksanaan</span>
              </div>
              <ul class="shadule mb-5">
                <li v-for="(item, idx) in event.session_schedules" :key="idx">
                  <div class="schedule-time">
                    <span
                      >{{ moment2(item.date_start) }}
                      {{
                        item.date_end ? "- " + moment2(item.date_end) : ""
                      }}</span
                    >
                    <span
                      >: {{ event.session_schedules[0].start_time }} -
                      {{ event.session_schedules[0].end_time }} WIB</span
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="info-schadule"
              style="display: block"
              v-if="event.method === 'Offline'"
            >
              <div class="info-schadule-ctn mb-2">
                <MapIc :width="25" fill="#7B7E8C" />
                <span style="margin-top: -3px">Lokasi Pelaksanaan</span>
              </div>
              <div class="ml-8">
                <h3>{{ event.location_name }}</h3>
                <p>{{ event.location_address }}</p>
                <!-- <p v-if="event.location_map">
                  <a :href="event.location_map">{{ event.location_map }}</a>
                </p> -->
              </div>
            </div>
            <div class="alert-early" v-if="checkIsEarlyBird(event)">
              <span
                >Ada
                <span style="color: #162ca2; font-weight: bolder"
                  >Promo Early Bird</span
                >
                jika HiColers daftar event ini sekarang!</span
              >
            </div>
          </div>
          <div v-if="event.syllabuses.length > 0">
            <h2 class="mt-10 mekenisme-text">Materi Silabus</h2>
            <div
              class="materi-silabus"
              v-for="(item, idx) in event.syllabuses"
              :key="idx"
            >
              <span class="sub-title">Sesi {{ idx + 1 }}</span>
              <p style="font-size: 16px">
                {{
                  session_schedules[idx]
                    ? moment2(session_schedules[idx].date_start)
                    : ""
                }}
              </p>
              <ul>
                <li v-for="(item2, idx2) in item" :key="idx2">{{ item2 }}</li>
              </ul>
            </div>
          </div>
          <h2 class="mt-5 mekenisme-text" v-if="event.instructors.length">
            Narasumber
          </h2>
          <div
            v-for="item2 in event.instructors"
            :key="item2.id"
            class="narasumber"
          >
            <div>
              <img
                :src="$utils.loadImage(item2.image_url)"
                alt="Profile"
                class="narasumber-img"
              />
            </div>
            <div>
              <div>
                <h2>{{ item2.name }}</h2>
                <span class="sub-title-narasumber"
                  >{{ item2.position }}
                  {{ item2.company ? "at " + item2.company : "" }}</span
                >
              </div>
              <p class="description-2">{{ item2.description }}</p>
            </div>
          </div>

          <h2 class="benefit mekenisme-text">Benefit</h2>
          <div
            v-for="(item, idx) in event.benefits"
            :key="idx"
            class="benefit-list"
          >
            <div>
              <complete fill="#162CA2" />
            </div>
            <span style="margin: 2px">{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="event-content-r">
        <div class="event-price-detail">
          <h2 class="line-height-normal">{{ event.name }}</h2>
          <div class="event-ctn-label">
            <span>{{ event.category.name }}</span>
          </div>
          <div class="event-ctn-info">
            <DateIcon fill="#575966" />
            <span class="name">{{
              moment(event.session_schedules[0].date_start)
            }}</span>
          </div>
          <div class="event-ctn-info">
            <clock fill="#575966" />
            <span class="name"
              >{{ event.session_schedules[0].start_time }} -
              {{ event.session_schedules[0].end_time }} WIB</span
            >
          </div>
          <div class="event-ctn-info">
            <methode fill="#575966" />
            <span class="name">{{ event.method | toMethode }}</span>
          </div>
          <div class="event-ctn-info" v-if="event.instructors.length">
            <speaker fill="#575966" />
            <span class="name"
              >{{ event.instructors[0].name
              }}<span v-if="event.instructors.length > 1"
                >, {{ event.instructors.length - 1 }}+ lainnya</span
              ></span
            >
          </div>
          <p class="event-price" v-if="!checkIsEarlyBird(event)">
            {{ event.price ? toCurrency(event.price) : "GRATIS" }}
          </p>
          <div v-else class="mb-2">
            <span class="event-price mr-2" style="font-size: 22px">{{
              event.price - event.discount_price
                ? toCurrency(event.price - event.discount_price)
                : "GRATIS"
            }}</span><del>{{ toCurrency(event.price) }}</del>
            <p><b style="color: #162ca2; font-family: 'Poppins';">Early Bird Promo</b>! Diskon {{Math.round(((event.price - (event.price - event.discount_price))/(event.price))*100)}}%</p>
          </div>
          <div class="event-due-date" v-if="event.expired.length">
            <div class="event-due-date-ctn">
              <span>{{ event.expired[0] }}</span>
              <span>Hari</span>
            </div>
            <span>:</span>
            <div class="event-due-date-ctn">
              <span>{{ event.expired[1] }}</span>
              <span>Jam</span>
            </div>
            <span>:</span>
            <div class="event-due-date-ctn">
              <span>{{ event.expired[2] }}</span>
              <span>Menit</span>
            </div>
            <span>:</span>
            <div class="event-due-date-ctn">
              <span>{{ event.expired[3] }}</span>
              <span>Detik</span>
            </div>
          </div>
          <div class="button-checkout" v-if="event.expired.length && !isFullRegistrant">
            <button-yellow
              name="DAFTAR SEKARANG"
              width="300px"
              @click="() => toCheckout()"
            />
            <button-outline
              name="KONSULTASI GRATIS"
              width="300px"
              @click="() => toKonsultasi()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ButtonOutline from "../../others/ButtonOutline.vue";
import ButtonYellow from "../../others/ButtonYellow.vue";

import Arrow from "@/assets/icons/Arrow.vue";
import Complete from "@/assets/icons/Complete.vue";
import Clock from "@/assets/icons/Clock.vue";
import DateIcon from "@/assets/icons/Date.vue";
import Speaker from "@/assets/icons/Speaker.vue";
import Methode from "@/assets/icons/Methode.vue";
import ClockIc from "@/assets/icons/Clock.vue";
import MethodeIc from "@/assets/icons/Methode.vue";
import DateIc from "@/assets/icons/Date.vue";
import GrowIc from "@/assets/icons/Grow.vue";
import PeopleIc from "@/assets/icons/People.vue";
import MapIc from "@/assets/icons/Map.vue";

export default {
  name: "EventDetailDesktop",
  components: {
    Arrow,
    ButtonOutline,
    ButtonYellow,
    DateIcon,
    Clock,
    Methode,
    Speaker,
    Complete,
    ClockIc,
    MethodeIc,
    DateIc,
    GrowIc,
    PeopleIc,
    MapIc,
  },
  data() {
    return {
      event: {
        name: "",
        learning_url: "",
        caption: "",
        img_urls: {
          desktop: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
          mobile: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
        },
        description: "",
        method: "",
        total_session: 0,
        day_session: "",
        benefits: [],
        session_schedules: [],
        instructors: [],
        registration_close_date: "",
        price: 0,
        syllabuses: [],
        expired: [],
      },
      isFullRegistrant: false,
      session_schedules: [],
      isAlertErr: false,
      total_registrants: 0
    };
  },
  methods: {
    async getEventDetail() {
      try {
        const resp = await this.$http.get("/v1/event/" + this.$route.params.id);
        const item = resp.data.data.event;
        this.event = {
          ...item,
          session_schedules: item.session_schedules
            ? item.session_schedules
              ? item.session_schedules
              : [
                  {
                    date_end: "",
                    date_start: "",
                    end_time: "",
                    start_time: "",
                  },
                ]
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        };
        this.session_schedules = this.event.session_schedules
        if (
          this.event.session_schedules.length === 1 &&
          this.event.session_schedules[0].date_start &&
          this.event.session_schedules[0].date_end
        ) {
          this.sessionSchedulesDateStartToEnd();
        }
        this.getTotalRegistran()
      } catch (err) {
        console.log(err);
      }
    },
    async getTotalRegistran() {
      try {
        const resp = await this.$http.get(`/v1/event/${this.event.id}/registrant`);
        this.total_registrants = resp.data.data.total_registrants  
        if (this.total_registrants >= this.event.participant_quota && this.event.participant_quota !== 0) {
          this.isFullRegistrant = true
        }
      } catch (error) {
        console.log(error);
      }
    },
    totalRegistrant(total) { 
      return (total / 100) * 100
    },
    toCheckout() {
      this.$router.push("/checkout/event/" + this.event.id);
    },
    toKonsultasi() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    moment2(date) {
      return moment(date).format("DD MMM YYYY");
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    checkIsEarlyBird(item) {
      if (
        new Date(item.start_discount_date) <= new Date() &&
        new Date(item.end_discount_date) >= new Date()
      ) {
        return true;
      } else {
        return false;
      }
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(this.event.registration_close_date);
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.event.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.event.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
    sessionSchedulesDateStartToEnd() {
      const getDaysArray = function (s, e) {
        for (
          var a = [], d = new Date(s);
          d <= new Date(e);
          d.setDate(d.getDate() + 1)
        ) {
          a.push(new Date(d));
        }
        return a;
      };
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      const end = new Date(this.event.session_schedules[0].date_end);
      const now = new Date(this.event.session_schedules[0].date_start);
      const distance = end - now;
      const days = Math.floor(distance / _day);
      for (let i = 0; i < days; i++) {
        const daylist = getDaysArray(new Date(now), new Date(end));
        this.session_schedules = daylist.map((v) => ({
          date_end: "",
          date_start: v.toISOString().slice(0, 10),
          end_time: "",
          start_time: "",
        }));
      }
    },
  },
  mounted() {
    this.getEventDetail();
    this.countDownTimer();
    window.scrollTo(0, 0);
  },
};
</script>
<style src="./eventDetail.css" scoped></style>
