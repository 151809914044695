export default {
    loadImage(image) {
        if (image && image != "-") return image
        return '/img/other/ic_profile.png'
    },
    paginate(totalData, itemsPerPage) {
        const numPages = Math.ceil(totalData / itemsPerPage);
        const pages = [];
    
        for (let i = 0; i < numPages; i++) {
            const start = i * itemsPerPage;
            const end = Math.min(start + itemsPerPage, totalData);
            pages.push({ page: i + 1, start: start, end: end });
        }
    
        return pages[pages.length-1].page;
    },
    scrollTop() {
        window.scrollTo(0, 0)
    },
    getMonthDifference(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        let months = ((end.getFullYear() - start.getFullYear()) * 12) + (end.getMonth() - start.getMonth());
        let days = end.getDate() - start.getDate();
    
        if (days < 0) {
            months--;
            days += new Date(end.getFullYear(), end.getMonth(), 0).getDate(); // Tambah hari dari bulan sebelumnya
        }
        return `${months} Bulan ${days} Hari`
    }
      
}