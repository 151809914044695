<template>
  <div style="margin-top: 45px">
    <img :src="classDetail.image_url" class="img-banner" />
    <div class="left-banner">
      <div class="d-flex" style="justify-content: center; margin-top: 10px">
        <div class="btn-white-oval-l">
          <img src="/img/class/prakerja.png" width="100px" />
        </div>
        <div class="btn-white-oval-l font-poppins">Webinar</div>
      </div>
      <div style="margin-top: 15px">
        <span class="text-banner">{{ classDetail.name }}</span>
      </div>
      <div class="description-class mt-4">
        <div>
          <BarIc fill="#7B7E8C" />
          <span class="font-poppins">{{ classDetail.level }}</span>
        </div>
        <div>
          <ScreenIc fill="#7B7E8C" />
          <span class="font-poppins">{{ classDetail.method | toMethode }}</span>
        </div>
      </div>
      <div style="width: 90%; margin: 30px auto;">
        <Button name="DAFTAR SEKARANG" @click="() => goToCheckoutPage()" width="100%" height="50px" />
      </div>
    </div>
    <div style="padding: 0px 16px; margin-top: -20px">
      <span class="font-poppins">Info Kelas</span>
      <h5 class="title">Deskripsi Program</h5>
      <p class="desc">{{ classDetail.description }}</p>
      <h5 class="title">Jadwal Kelas</h5>
      <div class="timeline mt-5" v-for="(item, idx) in classDetail.schedules" :key="idx">
        <div class="timeline-box">
          <div class="ctn">
            <DateIc2 />
            <div style="width: 90%;">
              <span class="font-poppins">{{ formatDate(item.start_date) }} - {{ formatDate(item.end_date) }}</span>
              <div>{{ item.hours }} WIB</div>
              <div style="color: #01A368" class="font-poppins">Tersedia</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-steps" style="border-radius: 20px; border: 1px #d3d6e1 solid; margin-top: 50px;">
        <div class="box-steps-ctn py-4" style="display: flex;" @click="isDetailClass = !isDetailClass">
          <h5 class="title">Detail Kelas</h5>
          <ArrowIc :down="!isDetailClass" />
        </div>
        <div class="box-steps-ctn border-top" v-if="isDetailClass">
          <p v-html="classDetail.learning_objective"></p>
          <h3 class="my-3">Sasaran Peserta</h3>
          <ul>
            <li v-for="(item, idx) in classDetail.target_audience" :key="idx" class="font-poppins">{{ item.level }}</li>
          </ul>
          <h3 class="my-3">Metode Penyampaian</h3>
          <p v-html="classDetail.learning_method"></p>
          <h3 class="my-3">Perangkat Evaluasi</h3>
          <ul>
            <li v-for="(item, idx) in evaluasi" :key="idx" class="font-poppins">{{ item }}</li>
          </ul>
          <h3 class="my-3">Syarat & Ketentuan Mengikuti Kelas</h3>
          <ul>
            <li v-for="(item, idx) in classDetail.requirements" :key="idx" class="font-poppins">{{ item.name }}</li>
          </ul>
          <div class="btn-hide" @click="isDetailClass = !isDetailClass">
            <arrow-ic />
            <span>SEMBUNYIKAN</span>
          </div>
        </div>
      </div>
    </div>

    <div class="materi-section">
      <span class="font-poppins">Materi Silabus</span>
      <h5 class="title">Apa saja yang Peserta Pelajari</h5>
      <div v-for="(item, idx) in classDetail.syllabuses" :key="idx" class="box-steps">
        <div class="box-steps-ctn pb-3" style="display: flex;" @click="item.show = !item.show">
          <div>
            <span class="font-poppins">Sesi {{ idx + 1 }}</span>
            <h4 v-for="(item2, idx2) in item.name" :key="idx2">{{ item2 }}</h4>
          </div>
          <ArrowIc :down="!item.show" />
        </div>
        <div class="box-steps-ctn border-top pa-5" v-if="item.show">
          <div class="list-art" v-if="idx === 0">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Pre-Test</p>
              <p style="color: #7B7E8C">Initial Exam</p>
            </div>
          </div>
          <div class="list-art">
            <img src="/img/icons/easel.png" alt="File">
            <div class="info-ars">
              <p>Live Session : {{ item.name[0] }}</p>
              <p style="color: #7B7E8C">Live & Share Deck</p>
            </div>
          </div>
          <div class="list-art">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Formative Test</p>
              <p style="color: #7B7E8C">Quiz {{ idx + 1 }}</p>
            </div>
          </div>
          <div class="list-art" v-if="idx < 4">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Tugas Praktik Mandiri {{ idx + 1 }}</p>
              <p style="color: #7B7E8C">Individual Assignment</p>
            </div>
          </div>
          <div class="list-art" v-if="idx >= classDetail.syllabuses.length - 1">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Post-Test</p>
              <p style="color: #7B7E8C">Final Exam</p>
            </div>
          </div>
          <div class="list-art" v-if="idx >= classDetail.syllabuses.length - 1">
            <img src="/img/icons/file.png" alt="File">
            <div class="info-ars">
              <p>Unjuk Keterampilan</p>
              <p style="color: #7B7E8C">Individual Assignment</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 0px 16px; margin-top: 8px; margin-bottom: 40px">
      <span>Benefit</span>
      <h5 class="title">Apa Saja yang Peserta Dapatkan</h5>
      <BenefitPrakerja style="margin-bottom: 58px" />
    </div>

    <div style="padding: 30px 16px">
      <span>Instruktur</span>
      <h5 class="title">Tentang Instruktur</h5>
      <div class="instructor">
        <img :src="$utils.loadImage(instructor.image_url)" alt="">
        <h5 class="title">{{ instructor.name }}</h5>
        <p>Instructor</p>
      </div>
      <p style="text-align: justify;">{{ instructor.description }}</p>
    </div>
  </div>
</template>

<script>
import BarIc from "@/assets/icons/BarIc.vue";
import ScreenIc from "@/assets/icons/ScreenIc.vue";
import ArrowIc from "@/assets/icons/Arrow.vue";
import BenefitPrakerja from "@/components/prakerja/BenefitPrakerja.vue";
import Button from "../../others/Button.vue";
import DateIc2 from '@/assets/icons/DateIc2.vue';
import moment from "moment";
export default {
  components: {
    BenefitPrakerja,
    Button,
    ArrowIc,
    BarIc,
    ScreenIc,
    DateIc2
  },
  data() {
    return {
      publicClass: [
        { message: "Lebih dari satu peserta (max 10)" },
        { message: "Jadwal kelas telah ditentukan" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      privateClass: [
        { message: "Hanya satu peserta" },
        { message: "Dapat menentukan jadwal kelas" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      evaluasi: [
        "Pre-Test",
        "Kuis",
        "Tugas Praktik Mandiri",
        "Post-Test",
        "Uji Keterampilan"
      ],
      faq: [
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
      ],
      menuPublicClass: [
        { name: "Info Kelas", id: 1 },
        { name: "Materi Silabus", id: 2 },
        { name: "Benefit", id: 3 },
        { name: "Path Career", id: 4 },
        { name: "Biaya Kelas", id: 5 },
        { name: "Alur Pendaftaran", id: 6 },
        { name: "FAQ", id: 7 },
      ],
      isDetailClass: true,
      classDetail: {
        requirements: [],
      },
      instructor: {}
    };
  },
  methods: {
    goToCheckoutPage() {
      var classType = "";
      if (this.$route.query.tipe_kelas == "prakerja") {
        classType = "PRAKERJA_CLASS";
      } else if (this.$route.query.tipe_kelas == "public-bootcamp") {
        classType = "BOOTCAMP_CLASS";
      }

      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: classType,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });

      localStorage.clear();
      localStorage.setItem("checkout", req);
      window.location.href = "/checkout";
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slugname)
        .then((response) => {
          this.classDetail = {
            ...response.data.data.class_detail,
            syllabuses: response.data.data.class_detail.syllabuses.map(item => ({
              ...item,
              name: item.description.split("<li>").map(item2 => item2.replace("</ul>", "").replace("</li>", "")),
              show: true,
            }))
          };
          this.classDetail.syllabuses = this.classDetail.syllabuses.map(item => {
            item.name.splice(0, 1)
            return {
              ...item,
              name: item.name
            }
          })
          this.instructor = response.data.data.instructor
          // this.getSchedule(this.classDetail.session_total, this.classDetail.category_id)
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    formatDate(date) {
      return moment(date).format("dddd, DD/MM/YYYY");
    },
  },
  mounted() {
    this.getDetailClass();
  },
};
</script>

<style src="./detailClass.css" scoped></style>