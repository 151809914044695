import { render, staticRenderFns } from "./EventDetail.vue?vue&type=template&id=1bb24b46&scoped=true&"
import script from "./EventDetail.vue?vue&type=script&lang=js&"
export * from "./EventDetail.vue?vue&type=script&lang=js&"
import style0 from "./eventDetail.css?vue&type=style&index=0&id=1bb24b46&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bb24b46",
  null
  
)

export default component.exports